import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

const BreadCrum: React.FC<any> = ({defaultTitle, defaultDescription, defaultHoverDescription}) => {
  const navigate = useNavigate()

  const location = useLocation()
  const [pageTitle, setPageTitle] = useState('')
  const [pageDescription, setPageDescription] = useState('')
  const [hoverDescription, setHoverDescription] = useState('')

  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const state: any = location.state

    if (state && state.pageTitle) {
      setPageTitle(state.pageTitle)
    }

    if (state && state.pageDescription) {
      setPageDescription(state.pageDescription)
    }

    if (state && state.hoverDescription) {
      setHoverDescription(state.hoverDescription)
    }
  }, [location.state])

  return (
    <div className='breadcrumb-heading d-flex flex-column align-items-start'>
      <h2 className='text-white mb-0 '>{pageTitle || defaultTitle}</h2>
      <p
        className='mb-0 mt-1 text-white cursor-pointer fw-semibold'
        onClick={() => {
          navigate(-1)
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (hoverDescription != '' || defaultHoverDescription)
          ? hoverDescription != ''
            ? hoverDescription
            : defaultHoverDescription
          : pageDescription || defaultDescription}
      </p>
    </div>
  )
}

export default BreadCrum
