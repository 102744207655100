import React, {useEffect, useState} from 'react'
import {getAuth, useAuth} from '../../../auth'
import {
  checkEmailSubscription,
  emailSubscription,
  getUserDetails,
  updateNotifications,
} from './core/_requests'
import Loader from '../../../accounts/components/Loading/Loader'
import MessageAlert from '../../global/MessageAlert'
import BreadCrum from '../../global/BreadCrum'

const NotificationsSettings = () => {
  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }

  // Alert Ends
  const [isEmailSubscribed, setIsEmailSubscribed] = useState(false)
  let auth = getAuth()

  const [isGettingDetails, setIsGettingDetails] = useState<Boolean>(false)
  const [isSaving, setIsSaving] = useState<Boolean>(false)

  const [notificationsDetails, setNotificationsDetails] = useState<any>({
    essential: true,
    openTracking: false,
    weeklyReports: false,
    monthlyReports: false,
  })
  const handleNotificationChange = (e: any) => {
    setNotificationsDetails((prev: any) => ({...prev, [e.target.name]: e.target.checked}))
  }
  const getNotificationData = () => {
    setIsGettingDetails(true)
    getUserDetails(
      auth?.uid,
      (res) => {
        setNotificationsDetails({
          essential: res?.notificationsSettings?.essential || true,
          openTracking: res?.notificationsSettings?.openTracking || false,
          weeklyReports: res?.notificationsSettings?.weeklyReports || false,
          monthlyReports: res?.notificationsSettings?.monthlyReports || false,
        })
        setIsGettingDetails(false)
      },
      (err: any) => {
        setMessage(err || 'User not found')
        setAlertType('error')
        alertToggle()
        setIsGettingDetails(false)
      }
    )
  }

  const handleUpdate = () => {
    setIsSaving(true)
    updateNotifications(
      auth?.uid,
      notificationsDetails,
      (res: any) => {
        setMessage(res || 'Data updated')
        setAlertType('success')
        alertToggle()
        setIsSaving(false)
      },
      (err: any) => {
        setMessage(err || 'Oops something went wrong')
        setAlertType('error')
        alertToggle()
        setIsSaving(false)
      }
    )
  }
  const {currentUser} = useAuth()
  const [isEmailSubscribeLoading, setIsEmailSubscribeLoading] = useState(false)
  const handleEmailSubscription = async () => {
    setIsEmailSubscribeLoading(true)
    await emailSubscription(currentUser?.email)
    setTimeout(() => {
      setIsEmailSubscribeLoading(false)
    }, 1000)
  }

  useEffect(() => {
    checkEmailSubscription(currentUser?.email, (res) => {
      if (res) {
        setIsEmailSubscribed(res)
      } else if (!res) {
        setIsEmailSubscribed(res)
      } else {
        console.log('something went wrong')
      }
    })
  }, [])

  useEffect(() => {
    getNotificationData()
  }, [])
  return (
    <>
      {isEmailSubscribeLoading && <Loader />}
      <div className='d-md-flex justify-content-between align-items-center my-lg-5 my-0'>
        {/* <div className='breadcrumb-heading'>
          <h2 className='text-white mb-0'>Notifications / Settings</h2>
        </div> */}
        <BreadCrum defaultTitle='Settings' defaultDescription='Notifications > Settings' />
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer border-bottom border-2'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Notifications</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          {isGettingDetails ? (
            <div className='d-flex justify-content-center py-5 px-2'>
              <Loader />
            </div>
          ) : (
            <div className='row g-0'>
              <div className=' col-12'>
                <div className='d-flex flex-stack'>
                  <div className='me-5'>
                    <label className='fs-6 fw-semibold form-label'>Essential</label>
                  </div>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      checked={notificationsDetails?.essential}
                      name='essential'
                      onChange={handleNotificationChange}
                      className='form-check-input'
                      type='checkbox'
                      disabled
                    />
                  </label>
                </div>
                <div className='d-flex flex-stack mt-3'>
                  <div className='me-5'>
                    <label className='fs-6 fw-semibold form-label'>Open and Tracking</label>
                  </div>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      checked={notificationsDetails?.openTracking}
                      name='openTracking'
                      onChange={handleNotificationChange}
                      className='form-check-input'
                      type='checkbox'
                    />
                  </label>
                </div>
                <div className='d-flex flex-stack mt-3'>
                  <div className='me-5'>
                    <label className='fs-6 fw-semibold form-label'>Weekly Reports</label>
                  </div>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      checked={notificationsDetails?.weeklyReports}
                      name='weeklyReports'
                      onChange={handleNotificationChange}
                      className='form-check-input'
                      type='checkbox'
                    />
                  </label>
                </div>
                <div className='d-flex flex-stack mt-3'>
                  <div className='me-5'>
                    <label className='fs-6 fw-semibold form-label'>Monthly Reports</label>
                  </div>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      checked={notificationsDetails?.monthlyReports}
                      name='monthlyReports'
                      onChange={handleNotificationChange}
                      className='form-check-input'
                      type='checkbox'
                    />
                  </label>
                </div>
                <div className='d-flex flex-stack mt-3'>
                  <div className='me-5'>
                    <label className='fs-6 fw-semibold form-label'>Email Subscription</label>
                  </div>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      checked={isEmailSubscribed}
                      name='monthlyReports'
                      onChange={handleEmailSubscription}
                      className='form-check-input'
                      type='checkbox'
                    />
                  </label>
                </div>

                <div className='d-flex justify-content-end mt-5'>
                  <button
                    className='btn btn-primary align-self-center'
                    data-kt-billing-action='card-delete'
                    disabled={isSaving ? true : false}
                    onClick={handleUpdate}
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </>
  )
}

export default NotificationsSettings
