/* eslint-disable jsx-a11y/anchor-is-valid */
import {KeyboardEvent, useEffect, useState} from 'react'
import {BsSearch} from 'react-icons/bs'
import {useNavigate} from 'react-router-dom'
import {
  addRecentSearchedData,
  getRecentSearchedData,
} from '../../../../app/modules/apps/notifications/components/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
interface HeaderSearchMenuProps {
  closeDropdown: () => void
}

const HeaderSearchMenu: React.FC<HeaderSearchMenuProps> = ({closeDropdown}) => {
  const [recentSearched, setRecentSearched] = useState([])
  const {currentUser} = useAuth()
  const [quickSearch, setquickSearch] = useState('')
  const navigate = useNavigate()

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && quickSearch) {
      let data = {searchString: quickSearch, id: currentUser?.uid}
      handleSearch()
      const prevVal = recentSearched.filter((item: any) => item.searchString === quickSearch)
      console.log(prevVal)
      if (prevVal.length === 0) {
        addRecentSearchedData(data)
      }
    }
  }

  const handleSearch = () => {
    navigate(`/search/overview`, {state: quickSearch})
    closeDropdown()
    setquickSearch('')
  }
  useEffect(() => {
    getRecentSearchedData(currentUser?.uid, (data) => {
      setRecentSearched(data)
    })
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-275px w-lg-375px'
      data-kt-menu='true'
      id='search-main-menu'
    >
      <div className='row justify-content-between rounded-top my-6 px-6 flex-nowrap'>
        <div className='input-group'>
          <span className='input-group-text border-0'>
            <BsSearch size={18} />
          </span>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder='Search...'
            name='search'
            value={quickSearch}
            onChange={(e) => setquickSearch(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>
      <div className='row justify-content-between rounded-top px-6'>
        <div className='col'>
          <h3 className='fw-semibold text-nowrap mb-0'>Recently Searched:</h3>
        </div>
      </div>

      <div className='separator my-5'></div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-225px mh-lg-375px mb-5 px-8'>
            {recentSearched.map((item: any) => (
              <div className={`mb-2 p-2 rounded cursor-pointer`} key={item.id}>
                <h6
                  className='text-gray-500 cursor-pointer fw-semibold mb-0 text-break '
                  onClick={() => setquickSearch(item.searchString)}
                >
                  {item.searchString}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderSearchMenu}
