import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getRecordedVideoById} from '../../../../oportunities/Components/opportunity-list/core/_requests'
import Loader from '../../../../accounts/components/Loading/Loader'

const ViewRecordedVideo = () => {
  const params: any = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [recordedVideoData, setRecordedVideoData] = useState<any>()
  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        try {
          setIsLoading(true)
          const response = await getRecordedVideoById(params.id)
          setRecordedVideoData(response)
          setIsLoading(false)
        } catch (error) {
          console.log(error)
          setIsLoading(false)
        }
      }
      fetchData()
    }
  }, [])
  console.log('View recorded video', recordedVideoData)

  return (
    <div className='w-sm-75 w-100 mx-auto mb-3'>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded p-8'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='w-100'>
            <div className='pb-3 pb-xl-5'>
              <h2 className='fw-bold text-dark'>{recordedVideoData?.videoTitle}</h2>
            </div>

            <div className='mb-0'>
              <video
                controls={true}
                poster={recordedVideoData?.thumbnailUrl || ''}
                className='w-100 video-container  mt-1'
                crossOrigin='anonymous'
              >
                <source src={`${recordedVideoData?.videoUrl}#t=0.001`}></source>
                {recordedVideoData?.captions ? (
                  <track
                    src={recordedVideoData?.captions?.caption_url}
                    kind='subtitles'
                    srcLang='en'
                    label='English'
                    default
                  />
                ) : null}
              </video>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewRecordedVideo
