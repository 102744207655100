import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  handleBothOpportunityStats,
  handleOpportunityStats,
  handleVideoStats,
  updateRecordedVideo,
} from '../../../../oportunities/Components/opportunity-list/core/_requests'
interface ModalProps {
  handleInterestedClick: () => void
  showInterested: boolean
  sender: any
  videoData: any
  setVideoData: any
}
const InterestedModal: React.FC<ModalProps> = ({
  handleInterestedClick,
  showInterested,
  videoData,
  setVideoData,
  sender,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const validNumber = /\d+/
  const handleSubmit = async () => {
    if (videoData.callAction === 'requestCall') {
      try {
        setIsLoading(true)
        await updateRecordedVideo(videoData.id, phoneNumber)
        setIsLoading(false)
        setPhoneNumber('')
        if (videoData.recipientReaction === '') {
          handleOpportunityStats(videoData.opportunity, 'interested')
          setVideoData((prev: any) => ({...prev, recipientReaction: 'info-provided'}))
          handleVideoStats(videoData.id, 'info-provided')
        } else if (videoData.recipientReaction === 'not-interested') {
          await handleBothOpportunityStats(videoData.opportunity, 'interested', 'notInterested')
          setVideoData((prev: any) => ({...prev, recipientReaction: 'info-provided'}))
          handleVideoStats(videoData.id, 'info-provided')
        } else {
          setVideoData((prev: any) => ({...prev, recipientReaction: 'info-provided'}))
          handleVideoStats(videoData.id, 'info-provided')
        }
        handleInterestedClick()
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    } else if (videoData.callAction === 'default') {
      if (videoData.recipientReaction === '') {
        await handleOpportunityStats(videoData.opportunity, 'interested')
        setVideoData((prev: any) => ({...prev, recipientReaction: 'interested'}))
        await handleVideoStats(videoData.id, 'interested')
      } else if (videoData.recipientReaction === 'not-interested') {
        await handleBothOpportunityStats(videoData.opportunity, 'interested', 'notInterested')
        setVideoData((prev: any) => ({...prev, recipientReaction: 'interested'}))
        await handleVideoStats(videoData.id, 'interested')
      } else {
        setVideoData((prev: any) => ({...prev, recipientReaction: 'interested'}))
        handleVideoStats(videoData.id, 'interested')
      }
      handleInterestedClick()
    } else {
      handleInterestedClick()
    }
  }
  const handleDownloadFile = async () => {
    if (videoData.recipientReaction === '') {
      handleOpportunityStats(videoData.opportunity, 'interested')
      setVideoData((prev: any) => ({...prev, recipientReaction: 'download-file'}))
      handleVideoStats(videoData.id, 'download-file')
    } else if (videoData.recipientReaction === 'not-interested') {
      await handleBothOpportunityStats(videoData.opportunity, 'interested', 'notInterested')
      setVideoData((prev: any) => ({...prev, recipientReaction: 'download-file'}))
      handleVideoStats(videoData.id, 'download-file')
    } else {
      setVideoData((prev: any) => ({...prev, recipientReaction: 'download-file'}))
      handleVideoStats(videoData.id, 'download-file')
    }
  }

  return (
    <Modal show={showInterested} onHide={handleInterestedClick} centered>
      <Modal.Body className='p-0'>
        <div className='modal-dialog m-0 modal-dialog-centered'>
          <div className='modal-content'>
            <div className='d-flex justify-content-end p-3'>
              <button type='button' className='btn ' onClick={handleInterestedClick}>
                x
              </button>
            </div>
            <div className='d-flex justify-content-center mb-5'>
              {/* <i className='fa-regular fa-circle-check fa-5x text-success rounded-pill'></i> */}
              <img src='/media/svg/landscape-modals/tick.svg' width={65} height={65} alt='img' />
            </div>
            <div className='modal-body justify-content-center'>
              <div className='row'>
                <div className='col p-2'>
                  {videoData.callAction === 'default' && (
                    <div className='card mb-6 border border-danger border-dotted'>
                      <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                        <h1 className={`display-6 fw-normal fs-6 text-center`}>
                          You can contact sender using the details below:
                        </h1>
                        {sender.phone && (
                          <p className='text-nowrap fs-6 mb-1'>
                            Telephone: <a href={`tel:${sender.phone}`}>{sender.phone}</a>
                          </p>
                        )}
                        {sender.email && (
                          <p className='text-nowrap fs-6 mb-1'>
                            Email: <a href={`mailto:${sender.email}`} target='_blank'>{sender.email}</a>
                          </p>
                        )}
                        {sender.website && (
                          <p className='text-nowrap fs-6 mb-1'>
                            Website:{' '}
                            <a href={`http://${sender.website}`} target='_blank'>{sender.website}</a>
                           
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {videoData.callAction === 'fileDownload' && (
                    <div className='card mb-6 border border-danger border-dotted '>
                      <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                        <a
                          href=''
                          onClick={handleDownloadFile}
                          download={videoData.fileUrl}
                          className='btn btn-pink'
                        >
                          Download file
                        </a>
                      </div>
                    </div>
                  )}
                  {videoData.callAction === 'requestCall' && (
                    <div className='card mb-6 border border-danger border-dotted '>
                      <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                        <h1 className={`display-6 fw-normal text-center px-6 fs-6`}>
                          Submit your phone number below to receive a call-back
                        </h1>
                        <div className='w-100'>
                          <label htmlFor='ph' className='fw-bold'>
                            Your telephone number
                          </label>
                          <input
                            className='form-control'
                            id='ph'
                            value={phoneNumber}
                            onChange={(e) => {
                              let {value} = e.target
                              value = value.replace(/\D/g, '')

                              setPhoneNumber(value)
                            }}
                            name='phonre'
                            placeholder='Please enter your number'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='m-4 d-flex justify-content-center'>
              <button disabled={isLoading} className='btn btn-primary' onClick={handleSubmit}>
                {videoData.callAction === 'requestCall' ? 'Submit' : 'Close'}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default InterestedModal
