import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import Overview from './Overview'

const SearchPage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path='overview'
        element={
          <>
            <Overview />
          </>
        }
      />

      <Route index element={<Navigate to='/search/overview' />} />
    </Route>
  </Routes>
)

export default SearchPage
