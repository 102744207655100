import React, {useEffect, useState} from 'react'
import {Container, Form, Button, Card} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {sendQuery} from '../../apps/user-management/users-list/core/_requests'
import MessageAlert from '../../apps/global/MessageAlert'
import BreadCrum from '../../apps/global/BreadCrum'

const ContactUs: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const source = queryParams.get('source')
  const [isSending, setIsSending] = useState<Boolean>(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const handleChange = (e: any) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }

  // Alert Ends

  const handleSubmit = (e: any) => {
    e.preventDefault()
    // You can handle the form submission logic here, e.g., sending data to a server.
    console.log(formData)
    setIsSending(true)
    sendQuery(
      formData,
      () => {
        // alert('Query submitted successfully!')
        setMessage('Query submitted successfully!')
        setAlertType('success')
        alertToggle()
        setFormData({
          name: '',
          email: '',
          message: '',
        })
        setIsSending(false)
      },
      (err: any) => {
        setMessage(err || 'OOps something went wrong!')
        setAlertType('error')
        alertToggle()
        setIsSending(false)
      }
    )
  }
  useEffect(() => {
    if (source === 'your-plans') {
      setFormData((prev) => ({
        ...prev,
        message: 'I want to query about greetrs enterprise plan please get back to me on this',
      }))
    } else {
      setFormData((prev) => ({
        ...prev,
        message: '',
      }))
    }
  }, [source])

  return (
    <Container className='mt-5'>
      {/* <h2 className='text-white'>Contact Us</h2> */}
      <div className='mb-md-5'>
        <BreadCrum defaultTitle='Contact Us' defaultDescription='Greetrs > Contact Us' />
      </div>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='name' className='mb-5'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='email' className='mb-5'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter your email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='message' className='mb-5'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as='textarea'
                rows={4}
                placeholder='Enter your message'
                name='message'
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant='primary' type='submit' disabled={isSending ? true : false}>
              {isSending ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </Container>
  )
}

export default ContactUs
