import axios from 'axios'
import {deleteUploadedVideo} from './core/_requests'
const sha1 = require('js-sha1')

export async function deletePrevRecordedVideo(): Promise<void> {
  let public_id = localStorage.getItem('public_id') || ''
  let formData = new FormData()
  let timestamp = new Date().getTime().toString()
  let signature = sha1(
    `public_id=${public_id}&timestamp=${timestamp}${
      process.env.REACT_APP_CLOUDINARY_TIMESTAMP || ''
    }`
  )
  formData.append('public_id', public_id)
  formData.append('signature', signature)
  formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY || '')
  formData.append('timestamp', timestamp)

  try {
    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINAR_ID}/video/destroy`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  } catch (err) {}
}

export async function clearPreviousVideoData(): Promise<void> {
  let videoDocumentId = localStorage.getItem('video_document_id')
  deleteUploadedVideo(
    videoDocumentId,
    () => {
      localStorage.removeItem('video_document_id')
    },
    () => {}
  )
  localStorage.removeItem('animatedThumbnail')
  localStorage.removeItem('url')
  localStorage.removeItem('video_start_offset')
  localStorage.removeItem('video_end_offset')
  localStorage.removeItem('thumbnail_end_offset')
  localStorage.removeItem('thumbnail_start_offset')
  localStorage.removeItem('trimmedUrlWithWatermark')
  localStorage.removeItem('trimmedUrl')
  localStorage.removeItem('static_image')
  localStorage.removeItem('public_id')
  localStorage.removeItem('bytes')
  localStorage.removeItem('animatedThumbnailUrl')
}
