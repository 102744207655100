import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Step2} from './steps/Step2'
import {Step5} from './steps/Step5'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {Modal} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {
  getRequestedDataForRecordVideo,
  handleOpportunityStats,
  sendEmail,
  sendVideoAgainstRequest,
} from '../../../../oportunities/Components/opportunity-list/core/_requests'
import Loader, {FullPageOverlay} from '../../../../accounts/components/Loading/Loader'
import {
  checkEmailSubscription,
  getUserDetails,
} from '../../../notifications/components/core/_requests'
import {getMySubscription} from '../../../../accounts/core/_requests'
import {clearPreviousVideoData, deletePrevRecordedVideo} from '../../deleteCloudinaryVideo'
import MessageAlert from '../../../global/MessageAlert'
import EmailAnimation from '../../../global/EmailAnimation'

const RecordVideoForEmail = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  const [url, setUrl] = useState<string>('')
  const [captions, setCaptionProp] = useState<boolean>(false)
  console.log(captions, 'captionProp')
  const [show, setShow] = useState(false)
  const params: any = useParams()
  const [requestData, setRequestData] = useState<any>()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [totalSteps, setTotalSteps] = useState(2)
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({})
  const [creatorDetails, setCreatorDetails] = useState<any>({})
  // const [captions, setCaptions] = useState<any>(captionProp)

  console.log('URL===>>', url)
  useEffect(() => {
    if (params.id) {
      setIsLoading(true)
      const fetchRequestForVideo = async () => {
        const response: any = await getRequestedDataForRecordVideo(params.id)
        console.log(response)
        setRequestData(response)
        setIsLoading(false)
      }
      fetchRequestForVideo()
    }
  }, [])
  const getSubscriptionDetails = async () => {
    const response = await getMySubscription(
      requestData?.senderDetails?.id,
      () => {},
      () => {
        setSubscriptionDetails({})
      }
    )
    if (response) {
      setSubscriptionDetails(response.data)
    }
  }
  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }

  // Alert Ends
  const getCreatorData = (id: any) => {
    getUserDetails(
      id,
      (res) => {
        setCreatorDetails(res)
      },
      (err: any) => {
        setMessage(err || 'User not found')
        setAlertType('error')
        alertToggle()
      }
    )
  }

  useEffect(() => {
    if (requestData?.senderDetails?.id) {
      if (requestData?.senderDetails?.role == 'super-admin') {
        getCreatorData(requestData?.senderDetails?.id)
      } else {
        getCreatorData(requestData?.senderDetails?.createdById)
      }
      getSubscriptionDetails()
    }
  }, [requestData])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      let public_id = localStorage.getItem('public_id')

      if (public_id) {
        deletePrevRecordedVideo()
        setTimeout(() => {
          clearPreviousVideoData()
        }, 500)
      }
    }

    return () => {
      handleBeforeUnload()
    }
  }, [])
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const submitStep = async () => {
    console.log('first', url)
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex !== totalSteps) {
      stepper.current.goNext()
    } else {
      console.log('first')
      setIsSubmitting(true)
      let payload: any = {
        recipientEmail: requestData.recipientEmail,
        senderId: requestData?.requestedById || '',
        emailFor: 'confirmation-video',
        variables: {
          subject: title,
          recipientName: requestData.recipientName,
          // link: localStorage.getItem('trimmedUrlWithWatermark'),
          link: url,
        },
      }
      let videoSize: any = localStorage.getItem('bytes') || 0
      const gigabytes: any = videoSize / 1073741824
      let data = {
        requestId: requestData?.id,
        videoSenderName: requestData.recipientName,
        videoSenderEmail: requestData.recipientEmail,
        opportunity: requestData.opportunity,
        videoTitle: title,
        videoUrl: url,
        videoSize: gigabytes,
        captions,

        // videoUrl: localStorage.getItem('trimmedUrlWithWatermark'),
        // thumbnail: localStorage.getItem('animatedThumbnailUrl'),
      }
      console.log('first')
      checkEmailSubscription(requestData.recipientEmail, async (res) => {
        if (res) {
          await sendVideoAgainstRequest(
            data,
            creatorDetails,
            async (id) => {
              const {variables, ...restPayload} = payload
              const updatedPayload = {
                ...restPayload,
                variables: {
                  ...variables,
                  link: `${process.env.REACT_APP_BASE_URL}/auth/view-video/${id}`,
                },
              }
              await sendEmail(updatedPayload)
              handleOpportunityStats(requestData.opportunity, 'videosNotOpened')
              handleShow()
              setIsSubmitting(false)
              setShowBtn(false)
              clearPreviousVideoData()
              // localStorage.removeItem('animatedThumbnail')
              // localStorage.removeItem('animatedThumbnailUrl')
              // localStorage.removeItem('url')
              // localStorage.removeItem('video_start_offset')
              // localStorage.removeItem('video_end_offset')
              // localStorage.removeItem('thumbnail_end_offset')
              // localStorage.removeItem('thumbnail_start_offset')
              // localStorage.removeItem('trimmedUrlWithWatermark')
              // localStorage.removeItem('trimmedUrl')
            },
            (err: any) => {
              setMessage(err || 'Error in sending video')
              if (err & err?.includes('available quota')) {
                setAlertType('warning')
              } else {
                setAlertType('error')
              }
              alertToggle()
              setIsSubmitting(false)
            }
          )
        } else {
          setMessage(
            "This user has unsubscribed from greetrs and won't get your email kindly share a link with them"
          )
          setAlertType('error')
          alertToggle()
          setIsSubmitting(false)
        }
      })
    }
  }
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  // useEffect(() => {
  //   const obj = {
  //     caption_public_id: localStorage.getItem('caption_public_id'),
  //     caption_url: localStorage.getItem('caption_url'),
  //   }
  //   setCaptions(obj)
  // }, [])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10 w-100' id='kt_profile_details_view'>
        <div className='my-20 d-flex justify-content-center'>
          <Loader />
        </div>
      </div>
    )
  }
  return (
    <>
      {requestData && requestData.status === 'recorded' ? (
        <div className='card w-100' id='kt_profile_details_view'>
          <div className='my-20 d-flex justify-content-center'>
            <h2 className='text-center mx-2'>
              A video has already been recorded for this opportunity. Only one video can be recorded
              per request.
            </h2>
          </div>
        </div>
      ) : (
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          <div className='card d-xl-flex d-none justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
            <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
              <div className='stepper-nav '>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className={`stepper-icon w-40px h-40px`}>
                      <span className='text-danger stepper-check'>1</span>

                      <span className={`stepper-number `}>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Record Video</h3>
                      <div className='stepper-desc fw-semibold text-nowrap'>
                        Record and trim your video
                      </div>
                    </div>
                  </div>

                  <div className='stepper-line h-40px'></div>
                </div>

                <div className='stepper-item ' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div
                      className={`stepper-icon w-40px h-40px ${
                        stepper.current?.currentStepIndex === 2
                          ? 'bg-pink'
                          : 'bg-light-pink text-white'
                      }`}
                    >
                      <span className='text-danger stepper-check'>2</span>

                      <span
                        className={`stepper-number ${
                          stepper.current?.currentStepIndex === 2 ? '' : 'text-danger'
                        }`}
                      >
                        2
                      </span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Submit</h3>
                      <div className='stepper-desc fw-semibold text-nowrap'>Submit Your Video</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show} centered>
            <Modal.Body>
              <div className='modal-body justify-content-center'>
                <div className='d-flex justify-content-center mb-5'>
                  {/* <i className='fa-regular fa-circle-check fa-5x text-success rounded-pill'></i> */}
                  <img
                    src='/media/svg/landscape-modals/tick.svg'
                    width={65}
                    height={65}
                    alt='img'
                  />
                </div>
                <p className='text-center my-5'>Your Video has been submitted Successfully</p>
                <p className='text-center my-5'>Thank you for using greetrs</p>
              </div>
            </Modal.Body>
            <div className='m-4 d-flex justify-content-center'>
              <button
                onClick={() => {
                  handleClose()
                  navigate('/auth/login')
                }}
                className='btn btn-primary'
              >
                Close
              </button>
            </div>
          </Modal>

          <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
            <form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <div>
                  <div className='stepper-item d-lg-none mb-5 current'>
                    <div className='stepper-wrapper'>
                      <div
                        className={`stepper-icon w-40px h-40px 
                     bg-pink
                        
                        `}
                      >
                        <span className='text-danger stepper-check'>1</span>

                        <span className={`stepper-number `}>1</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Record Video</h3>
                        <div className='stepper-desc fw-semibold text-nowrap'>
                          Record and trim your video
                        </div>
                      </div>
                    </div>
                  </div>
                  <Step2
                    title={title}
                    setTitle={setTitle}
                    requestData={requestData}
                    url={url}
                    setUrl={setUrl}
                    setCaptionProp={setCaptionProp}
                    submitStep={submitStep}
                    setShowBtn={setShowBtn}
                    subscriptionDetails={subscriptionDetails}
                    creatorDetails={creatorDetails}
                  />
                </div>
              </div>
              {isSubmitting && (
                <FullPageOverlay>
                  <EmailAnimation />
                </FullPageOverlay>
              )}
              <div data-kt-stepper-element='content'>
                <div>
                  <div className='stepper-item mb-5 d-lg-none current '>
                    <div className='stepper-wrapper'>
                      <div
                        className={` stepper-icon w-40px h-40px 
                          bg-pink`}
                      >
                        <span className='text-danger stepper-check'>2</span>

                        <span
                          className={`stepper-number ${
                            stepper.current?.currentStepIndex === 2 ? '' : 'text-danger'
                          }`}
                        >
                          2
                        </span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Submit</h3>
                        <div className='stepper-desc fw-semibold text-nowrap'>
                          Submit Your Video
                        </div>
                      </div>
                    </div>
                  </div>
                  <Step5 url={url} />
                </div>
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className=' d-lg-flex'>
                  <button
                    onClick={prevStep}
                    type='button'
                    disabled={isSubmitting}
                    className='btn btn-lg btn-light-primary me-3 rounded-1'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>
                {showBtn && (
                  <div>
                    <button
                      type='button'
                      disabled={isSubmitting}
                      onClick={submitStep}
                      className='btn btn-lg btn-primary me-3 '
                    >
                      <span className='indicator-label'>
                        {isSubmitting ? 'Submitting' : 'Submit'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </>
  )
}

export {RecordVideoForEmail}
