import {HeaderWrapperRecordVideo} from '../../../_metronic/layout/components/header/HeaderWrapperRecordVideo'
import clsx from 'clsx'
import {useLayout} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const DynamicLayout: React.FC<any> = ({children, themeData}) => {
  const {classes} = useLayout()
  console.log('themeData', themeData)
  return (
    <div className='page d-flex flex-row flex-column-fluid'>
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div
          style={
            themeData
              ? {
                  height: '260px',
                  width: '100%',
                  zIndex: 9,
                  backgroundColor: themeData?.headerColor,
                }
              : {
                  height: '260px',
                  width: '100%',
                  zIndex: 9,
                  backgroundSize: 'cover',
                  backgroundImage: `url(${toAbsoluteUrl('/media/patterns/headernew-bg.PNG')})`,
                }
          }
        >
          <HeaderWrapperRecordVideo />
        </div>
        <div
          style={
            themeData
              ? {backgroundColor: themeData?.bodyColor, marginTop: '-120px'}
              : {marginTop: '-120px'}
          }
          id='kt_content'
          className='content d-flex flex-column flex-column-fluid'
        >
          <div className='container w-100 d-flex justify-content-center align-items-center'>
            <h2
              style={{
                marginTop: '-48px',
                zIndex: 11,
                textAlign: 'center',
                marginBottom: '12px',
                fontWeight: 'bold',
                fontSize: '28px',
              }}
            >
              {themeData?.welcomeText}
            </h2>
          </div>
          <div
            style={{zIndex: 10}}
            className={clsx(
              'd-flex flex-column-fluid align-items-start',
              classes.contentContainer.join(' ')
            )}
            id='kt_post'
          >
            {children}
          </div>
          <div className='container py-4 d-flex justify-content-center align-items-center'>
            <h2>{themeData?.footerText}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DynamicLayout
