import Repository from './Repository'

export default {
  createBilling(payload: any) {
    return Repository.post('/payment/create-card', payload)
  },
  addCoupon(payload: any) {
    return Repository.post('/payment/verify-coupon', payload)
  },

  createCustomerAndSubscribeFreePlan(payload: any) {
    return Repository.post('/payment/createCustomerAndSubscribeFreePlan', payload)
  },
}
