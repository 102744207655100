import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'

export function MenuInner() {
  const {currentUser} = useAuth()
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem to='/opportunities/send-video-opportunity-overview' title='Video Messages' />
      <MenuItem to='/opportunities/request-video-overview/' title='Video Requests' />
      <MenuItem to='/contact' title='Contacts' />
      {currentUser?.role === 'super-admin' && <MenuItem to='/themes' title='Themes' />}
    </>
  )
}
