import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import { usersColumns } from './columns/_columns'
import { KTCardBody } from '../../../../../_metronic/helpers'
import { CustomRow } from './columns/CustomRow'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'

const ContactTable: React.FC<any> = ({data}) => {
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive' style={{minHeight: '240px'}}>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold' {...getTableBodyProps()}>
            {rows.map((row: Row<any>, i) => {
              prepareRow(row)
              return <CustomRow row={row} key={`row-${i}-${row.id}`} />
            })}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  )
}

export {ContactTable}
