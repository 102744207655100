import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === 'live'
    ? {
        // Live environment configuration
        apiKey: 'AIzaSyB-M-GCZJ_QQtqlZ1t5BGTX4TfsnMmVAtA',
        authDomain: 'app.greetrs.com',
        projectId: 'greetrz',
        storageBucket: 'greetrz.appspot.com',
        messagingSenderId: '857578385061',
        appId: '1:857578385061:web:b2af2065e35807030c674c', 
        measurementId: 'G-TSFJEXSPWW',
      }
    : {
        // Staging environment configuration
        apiKey: 'AIzaSyB1NBJDiaSGHsTlgQakmu3FOCe4a9m70Eo',
        authDomain: 'greetrs-staging.firebaseapp.com',
        projectId: 'greetrs-staging',
        storageBucket: 'greetrs-staging.appspot.com',
        messagingSenderId: '275246737487',
        appId: '1:275246737487:web:147d9c8c0cc382a6a4623a',
        measurementId: 'G-9BYBGCZ5HM',
      }

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const storage = firebase.storage()
export default firebase
