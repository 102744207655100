import {ID} from '../../../../../../_metronic/helpers'
import firebase from 'firebase/app'

const getUserDetails = async (id: ID, onSuccess = (res: any) => {}, onError = (err: any) => {}) => {
  firebase
    .firestore()
    .collection('users')
    .doc(id)
    .onSnapshot(
      async (query: any) => {
        if (query.data()) {
          onSuccess({id: query.id, ...query.data()})
        } else {
          onError('User not found!')
        }
      },
      (error: any) => {
        // alert(error?.message)
        onError(error?.message)
      }
    )
}

const getsmtpDetails = async (id: ID, onSuccess = (res: any) => {}, onError = (err: any) => {}) => {
  
  console.log(id,'id')

  firebase
    .firestore()
    .collection('users')
    .doc(id)
    .onSnapshot(
      async (querySnapshot: firebase.firestore.DocumentSnapshot) => {
        if (querySnapshot.exists) {
          const userData = querySnapshot.data();
          console.log(userData, 'User data');
          
          firebase.firestore().collection('smtpSettings').where("userId", "==", id).get()
            .then((snapshot: firebase.firestore.QuerySnapshot) => {
              snapshot.forEach((doc: firebase.firestore.DocumentData) => {
                const smtpData = doc.data();
                console.log(smtpData, 'SMTP data');
                onSuccess({ id: doc.id, ...smtpData });
              });
            })
            .catch((error: any) => {
              onError(error.message);
            });
        } else {
          onError('User not found!');
        }
      },
      (error: any) => {
        onError(error?.message);
      }
    );
};


const updateNotifications = async (
  id: ID,
  notificationsSettings: any,
  onSuccess = (res: any) => {},
  onError = (err: any) => {}
) => {
  firebase
    .firestore()
    .collection('users')
    .doc(id)
    .update({notificationsSettings})
    .then((res: any) => {
      // alert('Notifications settings updated successfully!')

      onSuccess('Notifications settings updated successfully!')
    })
    .catch((err: any) => {
      // alert(err?.message)
      onError(err?.message)
    })
}

const getLimitedDropdownNotifications = async (
  id: ID,
  onSuccess = (res: any) => {},
  onError = (res: any) => {}
) => {
  try {
    firebase
      .firestore()
      .collection('notifications')
      .where('userId', '==', id)
      .orderBy('createdAt', 'desc')
      .limit(5)
      .onSnapshot(async (query) => {
        let notificationslist = []
        for (let doc of query.docs) {
          let id = doc.id
          notificationslist.push({
            id: id,
            ...doc.data(),
          })
        }
        onSuccess(notificationslist)
      })
  } catch (error) {
    console.log(error)
    onError(error)
  }
}
const getNotifications = async (
  id: ID,
  onSuccess = (res: any) => {},
  onError = (res: any) => {}
) => {
  try {
    firebase
      .firestore()
      .collection('notifications')
      .where('userId', '==', id)
      .orderBy('createdAt', 'desc')
      .onSnapshot(async (query) => {
        let notificationslist = []
        for (let doc of query.docs) {
          let id = doc.id
          notificationslist.push({
            id: id,
            ...doc.data(),
          })
        }
        onSuccess(notificationslist)
      })
  } catch (error) {
    console.log(error)
    onError(error)
  }
}

const readNotification = async (id: any) => {
  try {
    const collectionRef = firebase.firestore().collection('notifications').doc(id)
    await collectionRef.set(
      {isRead: true, readAt: firebase.firestore.Timestamp.now()},
      {merge: true}
    )
    return 'success'
  } catch (error) {
    console.log(error)
  }
}
const emailSubscription = async (email: any) => {
  try {
    const collectionRef = firebase.firestore().collection('unsubscribeEmails')
    const querySnapshot = await collectionRef.where('email', '==', email).get()
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        try {
          await collectionRef.doc(doc.id).delete()
          console.log(`Document with email ${email} successfully deleted.`)
        } catch (deleteError) {
          console.error('Error deleting document: ', deleteError)
        }
      })
      return 'email subscribed'
    } else {
      try {
        await collectionRef.add({email: email})
        console.log(`Email ${email} added to a new document.`)
        return 'email unsubscribed'
      } catch (addError) {
        console.error('Error adding document: ', addError)
      }
    }
  } catch (error) {
    console.log(error)
  }
}
const checkEmailSubscription = async (email: any, onSuccess = (res: any) => {}) => {
  try {
    const collectionRef = firebase.firestore().collection('unsubscribeEmails')
    collectionRef.onSnapshot(async (snapshot) => {
      const querySnapshot = await collectionRef.where('email', '==', email).get()
      if (querySnapshot.empty) {
        onSuccess(true)
      } else {
        onSuccess(false)
      }
    })
  } catch (error) {
    console.log(error)
    onSuccess('error')
  }
}
const getRecentSearchedData = async (id: any, onSuccess = (res: any) => {}) => {
  try {
    const collectionRef = firebase.firestore().collection('recentSearches')

    const unsubscribe = collectionRef
      .where('createdBy', '==', id)
      .orderBy('createdAt', 'desc')
      .limit(20)
      .onSnapshot(async (snapshot) => {
        const data = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))

        if (snapshot.size > 20) {
          const excessDocs = snapshot.docs.slice(20)
          console.log(excessDocs)
          const deletePromises = excessDocs.map((doc) => doc.ref.delete())
          await Promise.all(deletePromises)
        }

        onSuccess(data)
      })

    // Return the unsubscribe function to allow unsubscribing when needed
    return unsubscribe
  } catch (error) {
    console.error(error)
    onSuccess({error})
  }
}

const addRecentSearchedData = async (data: any, onSuccess = (res: any) => {}) => {
  try {
    const collectionRef = firebase.firestore().collection('recentSearches')
    collectionRef.add({
      createdBy: data.id,
      searchString: data.searchString,
      createdAt: firebase.firestore.Timestamp.now(),
    })
  } catch (error) {
    console.log(error)
    onSuccess('error')
  }
}

export {
  getUserDetails,
  updateNotifications,
  getNotifications,
  getLimitedDropdownNotifications,
  readNotification,
  emailSubscription,
  checkEmailSubscription,
  addRecentSearchedData,
  getRecentSearchedData,
  getsmtpDetails
}
