import React, {useEffect} from 'react'
import {Modal} from 'react-bootstrap'

interface AlertModalProps {
  isOpen: boolean
  message: string
  toggle: () => void
  type: string
}

const MessageAlert: React.FC<AlertModalProps> = ({isOpen, message, toggle, type}) => {
  return (
    <Modal show={isOpen} centered className='message-alert'>
      <Modal.Body>
        <div className='d-flex flex-column align-items-center py-3'>
          {type && type == 'success' ? (
            <div className='alert-modal__icon alert-modal__icon-success mb-3'>
              <i className='fa fa-check '></i>
            </div>
          ) : type && type == 'error' ? (
            <div className='alert-modal__icon alert-modal__icon-error   mb-3 '>
              <i className='fa fa-times '></i>
            </div>
          ) : (
            <div className='alert-modal__icon alert-modal__icon-warning  mb-3 '>
              <i className='fa fa-exclamation '></i>
            </div>
          )}

          <p className='my-5 fw-bold text-center text-dark '> {message}</p>
          <div className='d-flex justify-content-center mt-3'>
            <button className='btn btn-primary' onClick={toggle}>
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MessageAlert
