import {FC} from 'react'

type Props = {
  user: any
}

const ContactPnumberCell: FC<Props> = ({user}) => (
  <>
    {' '}
    <div className=''>{user.telephone}</div>
  </>
)

export {ContactPnumberCell}
