/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials'
import {getAuth, useAuth} from '../../../../app/modules/auth'
import {Link} from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap'
import {HeaderSearchMenu} from '../../../partials/layout/header-menus/HeaderSearchMenu'
import {getUserDetails} from '../../../../app/modules/apps/notifications/components/core/_requests'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {currentUser} = useAuth()
  const [show, setShow] = useState(false)

  const auth = getAuth()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [userInfo, setUserInfo] = useState<any>({})

  const closeDropdown = () => {
    const dropdownElement = document.getElementById('notification-main-menu') // Use the same ID here
    if (dropdownElement) {
      dropdownElement.classList.remove('show')
    }
  }

  const closeSearchDropdown = () => {
    const dropdownElement = document.getElementById('search-main-menu') // Use the same ID here
    if (dropdownElement) {
      dropdownElement.classList.remove('show')
    }
  }

  const getUserData = () => {
    if (auth?.uid) {
      getUserDetails(
        auth?.uid,
        (res) => {
          setUserInfo(res)
        },
        (err: any) => {}
      )
    }
  }
  useEffect(() => {
    getUserData()
  }, [])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* <Link
          to={'/search/overview'}
          className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}
        >
          <div className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>{' '}
        </Link> */}

        {/* Search--START */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderSearchMenu closeDropdown={closeSearchDropdown} />
        </div>
        {/* Search--END */}

        {/* Activities */}
        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          {/* begin::Drawer toggle */}
          <Link
            to='/dashboard'
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            // id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </Link>
          {/* end::Drawer toggle */}
        </div>
        {/* videoCamera */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative btn-custom',
              toolbarButtonHeightClass
            )}
            onClick={handleShow}
            // data-kt-menu-trigger='click'
            // data-kt-menu-attach='parent'
            // data-kt-menu-placement='bottom-end'
            // data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/add_video.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          {/* <HeaderNotificationsMenu /> */}
          <Modal show={show} centered onHide={handleClose}>
            <Modal.Body className='p-md-12'>
              <h2 className='text-center my-6'>Send or Request a new video</h2>
              <div className='d-flex justify-content-center gap-4 mt-12 mb-20'>
                <Link
                  to='/videoMessages/record-video'
                  onClick={handleClose}
                  className='btn btn-pink w-100 d-flex align-items-center justify-content-center  gap-3'
                >
                  Send video
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/sendvideo.svg')}
                    className='logo-sticky h-15px'
                  />
                </Link>
                <Link
                  to='/opportunities/overview/request-video'
                  className='btn btn-success w-100 d-flex align-items-center justify-content-center  gap-3'
                  onClick={handleClose}
                >
                  Request video
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/sendvideo.svg')}
                    className='logo-sticky h-15px'
                  />
                </Link>
              </div>
              <div className='d-flex justify-content-center '>
                <button onClick={handleClose} className='btn btn-primary'>
                  Close
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* NOTIFICATIONS--START */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* <Link to='/notifications/settings'> */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {userInfo && userInfo?.unreadNotifications && userInfo?.unreadNotifications > 0 ? (
              <i className='fa fa-circle text-danger position-absolute bell-circle' />
            ) : (
              ''
            )}
            <KTSVG
              path='/media/icons/duotune/general/bell.svg'
              className={`${toolbarButtonIconSizeClass}`}
            />
          </div>
          <HeaderNotificationsMenu closeDropdown={closeDropdown} />
          {/* </Link> */}
        </div>
        {/* NOTIFICATIONS--END */}

        {/* CHAT */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div>
        </div> */}

        {/* Quick links */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          // <QuickLinks />
        </div> */}

        {/* begin::Theme mode */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div> */}
        {/* end::Theme mode */}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={userInfo?.profileImage || toAbsoluteUrl('/media/avatars/blank.png')}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
