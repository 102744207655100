import {KTCard} from '../../../../_metronic/helpers'
import {
  ListViewProvider,
  useListView,
} from '../contact/Components/contact-list/core/ListViewProvider'
import {ContactEditModal} from '../contact/Components/contact-list/contact-edit-modal/ContactEditModal'
import {ContactTable} from './contactTable/ContactTable'
import {QueryRequestProvider} from '../contact/Components/contact-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../contact/Components/contact-list/core/QueryResponseProvider'

const ContactList: React.FC<any> = ({data}) => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard className='mb-4'>
        <ContactTable data={data} />
      </KTCard>
      {itemIdForUpdate !== undefined && <ContactEditModal />}
    </>
  )
}

const ContactListWrapper: React.FC<any> = ({data}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContactList data={data} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ContactListWrapper}
