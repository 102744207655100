import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
// import {Helmet} from 'react-helmet'

import {
  getRecordedVideoById,
  handleBothOpportunityStats,
  handleOpportunityStats,
  handleOpportunityStatsDecrement,
  handleVideoStats,
} from '../../../oportunities/Components/opportunity-list/core/_requests'
import Loader from '../../../accounts/components/Loading/Loader'
// import {getContactById} from '../../contact/Components/contact-list/core/_requests'
import {getUserById} from '../../user-management/users-list/core/_requests'
import InterestedModal from './Modal/InterestedModal'
import NotInterestedModal from './Modal/NotInterestedModal'
import {getThemeById} from '../../theme/components/theme-list/core/_requests'
import DynamicLayout from '../../../auth/DynamicLayout'

const EmailReceived = () => {
  const {id} = useParams()
  const [videoData, setVideoData] = useState<any>()
  console.log(videoData, 'videoData')
  // const [contactDetails, setContactDetails] = useState<any>()
  const [sender, setSender] = useState<any>()
  const [themeData, setThemeData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setThemeData(null)
      const response = await getRecordedVideoById(id)
      if (response?.sendById) {
        const senderData = await getUserById(response.sendById)
        setSender(senderData)
      }
      if (response?.themeId) {
        const res = await getThemeById(response.themeId)
        setThemeData(res)
      }
      // if (response?.sendTo) {
      //   const res = await getContactById(response.sendTo)
      //   setContactDetails(res.data)
      // }
      setVideoData(response)
      if (response?.isOpened === false) {
        // await handleVideoStats(response.id, 'isOpened')
        await handleVideoStats(response.id, 'openedDate')
        await handleOpportunityStats(response.opportunity, 'videosOpened')
        await handleOpportunityStatsDecrement(response.opportunity, 'videosNotOpened')
      }
      await handleVideoStats(response.id, 'isOpened')

      setIsLoading(false)
    }
    fetchData()
  }, [id])

  const [showInterested, setInterestedShow] = useState(false)
  const [showNotInterested, setNotInterestedShow] = useState(false)

  const handleInterestedClick = () => {
    setInterestedShow(!showInterested)
  }

  const handleNotInterestedClick = async () => {
    if (videoData.recipientReaction === '') {
      handleOpportunityStats(videoData.opportunity, 'notInterested')
      setVideoData((prev: any) => ({...prev, recipientReaction: 'not-interested'}))
      handleVideoStats(videoData.id, 'not-interested')
      console.log('if called')
    } else if (
      videoData.recipientReaction === 'interested' ||
      videoData.recipientReaction === 'info-provided' ||
      videoData.recipientReaction === 'download-file'
    ) {
      await handleBothOpportunityStats(videoData.opportunity, 'notInterested', 'interested')
      setVideoData((prev: any) => ({...prev, recipientReaction: 'not-interested'}))
      handleVideoStats(videoData.id, 'not-interested')
      console.log('else if called')
    } else {
      setVideoData((prev: any) => ({...prev, recipientReaction: 'not-interested'}))
      handleVideoStats(videoData.id, 'not-interested')
      console.log('else called')
    }
    setNotInterestedShow(!showNotInterested)
  }

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const handlePlayEvent = () => {
      if (videoRef.current) {
        const trackList = videoRef.current.textTracks
        for (let i = 0; i < trackList.length; i++) {
          if (trackList[i].kind === 'subtitles' && trackList[i].language === 'en') {
            trackList[i].mode = 'showing'
          }
        }
      }
    }

    const videoElement = videoRef.current
    if (videoElement) {
      videoElement.addEventListener('play', handlePlayEvent)
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlayEvent)
      }
    }
  }, [])
  return (
    <DynamicLayout themeData={themeData}>
      {/* <Helmet>
        <title>Greetrz</title>
        <meta name='description' content='Greetrs' />
        <meta property='og:title' content='Greetrs' />
        <meta
          property='og:description'
          content='Hi I’ve recorded a completely unique and personalised video message just for you. I look forward to hearing what you think!'
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/greetrz.appspot.com/o/photo-check.jpg?alt=media&token=d2ce3b79-0346-40d2-ad65-4991cc4a5a05'
        />
      </Helmet> */}
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            {isLoading ? (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='my-20 d-flex justify-content-center'>
                  <Loader />
                </div>
              </div>
            ) : (
              <>
                {videoData ? (
                  <>
                    <div className='card p-5'>
                      <h3 className='fw-bold text-pink mb-md-5 mb-3'>{videoData?.videoTitle}</h3>
                      <div className='card-body p-0'>
                        <video
                          ref={videoRef}
                          onPlay={() => {
                            // if (videoData.partiallyWatched === false) {
                            handleVideoStats(videoData.id, 'partiallyWatched')
                            // }
                          }}
                          onEnded={() => {
                            if (videoData.fullyWatched === false) {
                              setVideoData((prev: any) => ({...prev, fullyWatched: true}))
                              handleOpportunityStats(videoData.opportunity, 'videosViewed')
                            }
                            handleVideoStats(videoData.id, 'fullyWatched')
                          }}
                          controls
                          poster={videoData?.thumbnailUrl || ''}
                          crossOrigin='anonymous'
                          className='card-img-top w-100 video-container p-5 '
                        >
                          <source src={`${videoData.videoUrl}#t=0.001`} type='video/mp4' />
                          {videoData?.captions == null ? (
                            ''
                          ) : (
                            <track
                              src={`${videoData?.captions?.caption_url}`}
                              kind='subtitles'
                              srcLang='en'
                              label='English'
                              default
                            />
                          )}
                        </video>
                      </div>
                      <div className='px-0 d-flex justify-content-center gap-4 flex-wrap'>
                        <button
                          className='btn btn-pink  rounded-1'
                          onClick={() => {
                            handleInterestedClick()
                          }}
                        >
                          {videoData.buttonText}
                        </button>
                        <button
                          className='btn btn-pink  rounded-1'
                          onClick={() => handleNotInterestedClick()}
                        >
                          I am not interested right now
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='card p-5'>
                    <p className='fw-bold'>
                      Oops! It appears that there are no records matching your search criteria.
                    </p>
                    <div className='card-body px-0'>
                      <p>Thanks,</p>
                      <p>greetrs</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {videoData && (
          <InterestedModal
            showInterested={showInterested}
            sender={sender}
            videoData={videoData}
            setVideoData={setVideoData}
            handleInterestedClick={handleInterestedClick}
          />
        )}
        {videoData && (
          <NotInterestedModal
            showNotInterested={showNotInterested}
            sender={sender}
            handleNotInterestedClick={handleNotInterestedClick}
          />
        )}
      </div>
    </DynamicLayout>
  )
}

export default EmailReceived
