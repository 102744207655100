import React from 'react'
import Modal from 'react-bootstrap/Modal'
interface ModalProps {
  handleNotInterestedClick: () => void
  showNotInterested: boolean
  sender: any
}
const NotInterestedModal: React.FC<ModalProps> = ({
  handleNotInterestedClick,
  showNotInterested,
  sender,
}) => {
  return (
    <Modal show={showNotInterested} onHide={handleNotInterestedClick} centered>
      <Modal.Body>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='d-flex justify-content-end p-3'>
              <button type='button' className='btn ' onClick={handleNotInterestedClick}>
                x
              </button>
            </div>
            <div className='modal-body justify-content-center'>
              <div className='d-flex justify-content-center mb-5'>
                {/* <i className='fa-regular fa-circle-check fa-5x text-success rounded-pill'></i> */}
                <img src="/media/svg/landscape-modals/tick.svg" width={65} height={65} alt="img" />
              </div>
              <p className='text-center my-5'>
                We’ve let {sender.firstName} know that you’re not interested right now!!
              </p>
            </div>
            <div className='m-4 d-flex justify-content-center'>
              <button className='btn btn-primary' onClick={handleNotInterestedClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotInterestedModal
