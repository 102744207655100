/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  requested: any
}

const OpportunityRequestedCell: FC<Props> = ({requested}) => {
  return (
    <>
      <div className=''>{requested?.opportunityStats?.videosRequested}</div>
    </>
  )
}

export {OpportunityRequestedCell}
