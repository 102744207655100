/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  viewed: any
}

const OpportunityViewedCell: FC<Props> = ({viewed}) => (
  <>
    <div className=''>{viewed?.opportunityStats.videosViewed}</div>
  </>
)

export {OpportunityViewedCell}
