/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {isValidEmail, isValidName} from '../../CreateAccountWizardHelper'

const Step6: FC<any> = ({userDetails, handleChange, userDetailsError, setUserDetailsError}) => {
  useEffect(() => {
    if (userDetailsError.name || userDetailsError.email) {
      const {name, email} = userDetails
      const errors = {name: false, email: false}

      if (!isValidName(name)) {
        errors.name = true
      }

      if (!isValidEmail(email)) {
        errors.email = true
      }

      setUserDetailsError(errors)
    }
  }, [userDetails])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-xl-15 d-none d-xl-block'>
        <h2 className='fw-bold d-flex align-items-center text-dark'>Your Name</h2>

        <div className='text-gray-400 fw-semibold fs-6'>Your Name and Email Address</div>
      </div>

      <div className='fw-row mb-10'>
        <label className='form-label mb-3 fw-bold'>
          Your Name <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          value={userDetails.name}
          placeholder='Enter your first and lastname'
          name='name'
          onChange={handleChange}
          className='form-control form-control-lg form-control-solid'
        />
        {userDetailsError.name && (
          <div className={`fv-plugins-message-container text-danger`}>
            <span role='alert'>First and last name is required</span>
          </div>
        )}
      </div>
      <div className='fw-row mb-10'>
        <label className='form-label mb-3 fw-bold'>
          Email Address <span className='text-danger'>*</span>
        </label>
        <input
          type='email'
          onChange={handleChange}
          value={userDetails.email}
          placeholder='Enter your email Address'
          name='email'
          className='form-control form-control-lg form-control-solid'
        />
        {userDetailsError.email && (
          <div className={`fv-plugins-message-container text-danger`}>
            <span role='alert'>A valid email is required</span>
          </div>
        )}
      </div>
    </div>
  )
}

export {Step6}
