export const formatTime = (createdAt: {seconds: number}) => {
  const date = new Date(createdAt.seconds * 1000)

  // Time options for formatting time components without seconds
  const timeOptions: any = {hour: 'numeric', minute: 'numeric', hour12: false}
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date)

  return formattedTime
}

export const formatCreatedAt = (createdAt: any) => {
  let seconds = createdAt.seconds
  const timestampMilliseconds = seconds * 1000

  const date = new Date(timestampMilliseconds)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
  const year = date.getFullYear().toString().slice(2)
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  const formattedDate = `${day}/${month}/${year} @ ${hours}:${minutes}`

  return formattedDate
}
