import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

const AllowCaptionsSection: React.FC<any> = ({allowCaptions, handleCaptionChange}) => {
  return (
    <div className='d-flex flex-column'>
      <label className='form-label mb-2 fw-bold'>Captions</label>
      <div className='d-flex flex-wrap justify-content-between flex-wrap'>
        <p className='mb-2 mr-2'>Automatically convert audio to text captions</p>
        <label className='mb-2 form-check form-switch form-check-custom form-check-solid'>
          <input
            checked={allowCaptions}
            onChange={handleCaptionChange}
            name='allowCaptions'
            className='form-check-input custom-switch'
            type='checkbox'
          />
        </label>
      </div>
    </div>
  )
}

export default AllowCaptionsSection
