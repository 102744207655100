// import {ListViewProvider, useListView} from './core/ListViewProvider'
// import {QueryRequestProvider} from './core/QueryRequestProvider'
// import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {KTCard} from '../../../../../_metronic/helpers'
// import {OpportunityTable} from './table/OpportunityTable'
// import {OpportunityEditModal} from './opportunity-edit-modal/OpportunityEditModal'
// import {OpportunityListHeader} from './components/header/OpportunityListHeader'
import {useLocation} from 'react-router-dom'
import {useListView} from '../opportunity/opportunity-list/core/ListViewProvider'
import {OpportunityEditModal} from '../opportunity/opportunity-list/opportunity-edit-modal/OpportunityEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import {SearchOpportunityTable} from './table/searchOpportunityTable'
const SearchOpportunityList = ({data}: any) => {
  const location = useLocation()
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <SearchOpportunityTable data={data} />
      </KTCard>
      {itemIdForUpdate !== undefined && <OpportunityEditModal />}
    </>
  )
}

const SearchOpportunityWrapper = ({data}: any) => <SearchOpportunityList data={data} />

export {SearchOpportunityWrapper}
