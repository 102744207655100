/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {continueWithGoogle, continueWithLinkedIn, getUserByToken, register} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {BsEye, BsEyeSlash} from 'react-icons/bs'
import MessageAlert from '../../apps/global/MessageAlert'
import { timeZones } from './RegisterTimezone'
import axios from 'axios'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changePassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
  .matches(/^[A-Za-z\s]+$/, 'First name must contain only alphabets')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastName: Yup.string()
  .matches(/^[A-Za-z\s]+$/, 'First name must contain only alphabets')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      'Password must include letters, symbols, and numbers'
    )
    .min(7, 'Minimum 7 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changePassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [confirmShowPassword, setConfirmShowPassword] = useState(false)
  const [country, setCountry] = useState<string>("")
  console.log(country,'ahsan')
  const [countryTimezone, setCountryTimezone] = useState<string>("");
  console.log(countryTimezone,'countryTimezone')
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()

  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    if (alertModal && alertType == 'success') {
      navigate('/auth/login')
    }

    setAlertModal(!alertModal)
  }

  // Alert Ends

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        let obj = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          timeZone: countryTimezone,
        }

        register(
          obj,
          values.password,
          (res: string) => {
            setMessage(res || 'Account created successfully')
            setAlertType('success')
            resetForm()
            setSubmitting(false)
            setLoading(false)
            alertToggle()
          },
          (err: any) => {
            setMessage(err || 'Unable to create account!')
            setAlertType('error')
            setSubmitting(false)
            setLoading(false)
            alertToggle()
          }
        )
        // await register(obj, values.password, (res: string) => {
        //   if (res == 'success') {
        //     resetForm()
        //     navigate('/auth/login')
        //   }
        //   setSubmitting(false)
        //   setLoading(false)
        // })
      } catch (error) {
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const fetchData = async (): Promise<void> => {
    try {
      const response = await axios.get<string>("https://api.ipify.org");
      const ipAddress: string = response.data;
  
      const countryDetail = await axios.get<{ city: string }>(
        `https://ipinfo.io/${ipAddress}/json?token=5a6086648352e7`
      );
      const { city } = countryDetail.data;

      setCountry(city)
  
      // console.log(city,countryDetail, 'country');
    } catch (error) {
      console.error("Error fetching IP data:", error);
    }
  };


  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    fetchData()
  }, [])

  useEffect(() => {
    console.log(timeZones.find(el => el.value === country), 'timeZones');
    const timezoneOfCountry = timeZones.find(el => el.value === country);
    console.log(timezoneOfCountry,'timezoneOfCountry')
    if (timezoneOfCountry) {
      setCountryTimezone(timezoneOfCountry.label);
    }
  }, [country])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Join now!</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-semibold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-pink fw-bold' style={{marginLeft: '5px'}}>
            Login here ?
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      <div className=' d-block'>
      <span
          onClick={() => {
            setLoading(true)
            continueWithGoogle(
              countryTimezone,
              (res: any) => {
                saveAuth(res)
                setCurrentUser(res)
                setLoading(false)
              },
              (err: any) => {
                setMessage(err || 'Unable to create account!')
                setAlertType('error')
                alertToggle()
                saveAuth(undefined)
                setLoading(false)
              }
            )

            // continueWithGoogle((res: any) => {
            //   if (res == 'failed') {
            //     saveAuth(undefined)
            //     setLoading(false)
            //   } else {
            //     saveAuth(res)
            //     setCurrentUser(res)
            //     setLoading(false)
            //   }
            // })
          }}
          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 me-md-2 me-0 border'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Join with Google
        </span>
        {/* <div
          onClick={() => {
            setLoading(true)
            continueWithLinkedIn((res: any) => {
              if (res == 'failed') {
                saveAuth(undefined)
                setLoading(false)
              } else {
                saveAuth(res)
                setCurrentUser(res)
                setLoading(false)
              }
            })
          }}
          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 ms-md-2 ms-0 border'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')}
            className='h-20px me-3'
          />
          Join in with Linkedin
        </div> */}
      </div>
      {/* end::Action */}

      <div className='text-center text-muted  mb-5 d-flex justify-content-between align-items-center'>
        <hr className='w-100' />
        <div className='w-100'>Or with email</div>
        <hr className='w-100' />
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <label className='class="form-label fw-bold text-dark fs-6'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            },
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group Lastname */}

      <div className='fv-row mb-7'>
        <label className='form-label fw-bold text-dark fs-6'>Last name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            },
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bold text-dark fs-6'>Email</label>
        <input
          // placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bold text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <div className='d-flex align-items-center'>
              <input
                type={showPassword ? 'text' : 'password'}
                // placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid'
                  // {
                  //   'is-invalid': formik.touched.password && formik.errors.password,
                  // }
                  // {
                  //   'is-valid': formik.touched.password && !formik.errors.password,
                  // }
                )}
              />
              <div className='position-absolute p-1 end-0 me-4'>
                {showPassword ? (
                  <BsEyeSlash
                    onClick={() => setShowPassword(!showPassword)}
                    size={20}
                    color='#6c6c6c'
                  />
                ) : (
                  <BsEye onClick={() => setShowPassword(!showPassword)} size={20} color='#6c6c6c' />
                )}{' '}
              </div>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bold text-dark fs-6'>Repeat Password</label>
        <div className='position-relative d-flex align-items-center'>
          <input
            type={confirmShowPassword ? 'text' : 'password'}
            // placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changePassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid'
              // {
              //   'is-invalid': formik.touched.changePassword && formik.errors.changePassword,
              // },
              // {
              //   'is-valid': formik.touched.changePassword && !formik.errors.changePassword,
              // }
            )}
          />
          <div className='position-absolute p-1 end-0 me-4'>
            {confirmShowPassword ? (
              <BsEyeSlash
                onClick={() => setConfirmShowPassword(!confirmShowPassword)}
                size={20}
                color='#6c6c6c'
              />
            ) : (
              <BsEye
                onClick={() => setConfirmShowPassword(!confirmShowPassword)}
                size={20}
                color='#6c6c6c'
              />
            )}{' '}
          </div>
        </div>
        {formik.touched.changePassword && formik.errors.changePassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changePassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-semibold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree with the{' '}
            <Link to='/auth/terms' className='ms-1 link-pink'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 '
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Join with email</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}

      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </form>
  )
}
