import axios from 'axios'
import firebase from '../../../config/firebase'
import {AuthModel, ResetPasswordResult, UserModel, UserRegister} from './_models'
import {enableUser} from '../../apps/user-management/users-list/core/_requests'
import {RepositoryFactory} from '../../../repository/RepositoryFactory'
let BillingApi = RepositoryFactory.get('billing')
let UserApi = RepositoryFactory.get('user')

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export async function login(
  email: string,
  password: string,
  onSuccess: (res: any) => void,
  onError: (res: any) => void
) {
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (data) => {
        let currentUser = firebase.auth().currentUser
        console.log(currentUser, 'currentUser by waseem')
        if (currentUser?.emailVerified) {
          let user = await firebase.firestore().collection('users').doc(data?.user?.uid).get()
          if (user?.exists) {
            if (user.data()?.status !== 'blocked') {
              onSuccess({uid: data?.user?.uid, ...user.data()})
              await firebase.firestore().collection('users').doc(data?.user?.uid).set(
                {
                  lastLoginTime: firebase.firestore.Timestamp.now(),
                },
                {merge: true}
              )
              createOrUpdateLoginHistory(data.user?.uid)
            } else {
              // alert('You are blocked by the Admin. For more details please contact Admin')
              // onSuccess('failed')
              onError('You are blocked by the Admin. For more details please contact Admin')
            }
          } else {
            // alert('User does not exist')
            // onSuccess('failed')
            onError('User does not exist')
          }
        } else {
          // alert('Email not verified kindly verify you email!')
          // onSuccess('failed')
          onError('Email not verified. kindly verify you email!')
        }
      })
      .catch(async (err) => {
        if (err.code === 'auth/user-disabled') {
          let value = window.prompt(
            `${err.message}  If you want to Reactivate account Please Enter "Reactivate" in the given prompt.`
          )
          if (value === 'Reactivate') {
            const userId = await getUserIdByEmail(email)
            if (userId) {
              await enableUser(
                userId,
                () => {
                  // alert('Account reactivated successfully. Please log in again.')
                  // onSuccess('reactive')
                  onSuccess('reactive')
                },
                (error) => {
                  console.log(error)
                  // onSuccess('failed')
                  onError('Unable to reactivate you account')
                }
              )
            } else {
              // alert('User not found for reactivation')
              // onSuccess('failed')
              onError('User not found for reactivation')
            }
          } else {
            onSuccess('failed')
          }
        } else {
          // alert(err.message)
          // onSuccess('failed')
          onError(err.message)
        }
      })
  } catch (e: any) {
    // console.log(e)
    // alert(e.message)
    // onSuccess('failed')
    onError(e.message)
  }
}

async function getUserIdByEmail(email: string) {
  const querySnapshot = await firebase
    .firestore()
    .collection('users')
    .where('email', '==', email)
    .get()
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].id
  }
  return null
}

export async function register(
  auth: UserRegister,
  password: string,
  onSuccess: (res: string) => void,
  onError: (res: string) => void
) {
  try {
    await firebase
      .auth()
      .createUserWithEmailAndPassword(auth.email, password)
      .then(async (data) => {
        const response = await BillingApi.createCustomerAndSubscribeFreePlan({
          email: auth.email,
          name: auth?.firstName + ' ' + auth?.lastName,
        })

        firebase
          .firestore()
          .collection('users')
          .doc(data?.user?.uid)
          .set({
            ...auth,
            role: 'super-admin',
            status: 'active',
            allowFreeAccess: false,
            createdAt: firebase.firestore.Timestamp.now(),
            platform: 'email',
            notificationsSettings: {
              essential: true,
              weeklyReports: false,
              monthlyReports: false,
              openTracking: false,
            },
            usage: {
              videoMessages: 0,
              users: 0,
              storage: 0,
            },
            priceId: response?.data?.data?.planDetails?.id,
            subscriptionId: response?.data?.data?.subscriptionDetails?.id,
            customerId: response?.data?.data?.customerId,
            unreadNotifications: 0,
          })
          .then(async () => {
            // alert('Account created successfully and verification email sent!')
            // onSuccess('success')
            await UserApi.sendVerificationEmail({
              firstName: auth?.firstName,
              lastName: auth?.lastName,
              email: auth?.email,
            })
            onSuccess('Account created successfully and verification email sent!')
          })
          .catch((e) => {
            // alert(e.message)
            // onSuccess('failed')
            onError(e.message)
          })
      })
      .catch((err) => {
        // alert(err.message)
        // onSuccess('failed')
        onError(err.message)
      })
  } catch (e: any) {
    // alert(e.message)
    // onSuccess('failed')
    onError(e?.message || e?.response?.data?.error)
  }
}

export async function getCurrentUserSnapshot(
  id: string | undefined,
  onSuccess: (res: any) => void
) {
  try {
    firebase
      .firestore()
      .collection('users')
      .doc(id)
      .onSnapshot(async (snapshot: any) => {
        if (snapshot?.exists) {
          let {role}: any = snapshot.data()
          if (role == 'super-admin') {
            onSuccess({uid: id, ...snapshot.data()})
          } else {
            let {createdById} = snapshot.data()
            let createdByDetails = await firebase
              .firestore()
              .collection('users')
              .doc(createdById)
              .get()
            if (createdByDetails.data()) {
              onSuccess({
                uid: id,
                ...snapshot.data(),
                createdByDetails: {id: createdById, ...createdByDetails.data()},
              })
            } else {
              onSuccess('failed')
            }
          }
        } else {
          onSuccess('failed')
        }
      })
  } catch (e: any) {
    alert(e.message)
    onSuccess('failed')
  }
}

export async function getCurrentUser(id: string | undefined, onSuccess: (res: any) => void) {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot?.exists) {
          onSuccess({uid: id, ...snapshot.data()})
        } else {
          onSuccess('failed')
        }
      })
  } catch (e: any) {
    alert(e.message)
    onSuccess('failed')
  }
}

export async function continueWithGoogle(
  countryTimezone: string,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) {
  console.log(countryTimezone, 'countryTimezonebyahsan')
  const provider = new firebase.auth.GoogleAuthProvider()
  await firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (data) => {
      const userInfo = data?.user?.displayName?.split(' ')
      console.log('userInfo', data)
      let user = await firebase.firestore().collection('users').doc(data?.user?.uid).get()
      if (user.data()) {
        if (user.data()?.status !== 'blocked') {
          onSuccess({uid: data?.user?.uid, ...user.data()})
          await firebase.firestore().collection('users').doc(data?.user?.uid).set(
            {
              lastLoginTime: firebase.firestore.Timestamp.now(),
            },
            {merge: true}
          )
          createOrUpdateLoginHistory(data.user?.uid)
        } else {
          // alert('You are blocked by the Admin. For more details please contact Admin')
          // onSuccess('failed')
          onError('You are blocked by the Admin. For more details please contact Admin')
        }
      } else {
        let firstName = userInfo ? (userInfo?.length > 0 ? userInfo?.[0] : '') : ''
        let lastName = userInfo ? (userInfo?.length > 1 ? userInfo?.[1] : '') : ''
        const response = await BillingApi.createCustomerAndSubscribeFreePlan({
          email: data?.user?.email,
          name: firstName + ' ' + lastName,
        })

        let obj = {
          firstName,
          lastName,
          email: data?.user?.email,
          profileImage: data?.user?.photoURL,
          timeZone: countryTimezone,
          role: 'super-admin',
          status: 'active',
          allowFreeAccess: false,
          createdAt: firebase.firestore.Timestamp.now(),
          notificationsSettings: {
            essential: true,
            weeklyReports: false,
            monthlyReports: false,
            openTracking: false,
          },
          usage: {
            videoMessages: 0,
            users: 0,
            storage: 0,
          },
          platform: 'gmail',
          priceId: response?.data?.data?.planDetails?.id,
          subscriptionId: response?.data?.data?.subscriptionDetails?.id,
          customerId: response?.data?.data?.customerId,
          unreadNotifications: 0,
        }
        firebase
          .firestore()
          .collection('users')
          .doc(data?.user?.uid)
          .set(obj)
          .then(() => {
            onSuccess({uid: data?.user?.uid, ...obj})
          })
          .catch((err) => {
            onError(err.message)
            // alert(err.message)
            // onSuccess('failed')
          })
      }
    })
    .catch((err) => {
      // alert(err.message)
      // onSuccess('failed')
      onError(err?.message || err?.response?.data?.error)
    })
}

export async function continueWithLinkedIn(
  mockedUser: any,
  countryTimezone: any,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) {
  try {
    console.log(mockedUser, 'resultlinkedin')
    const extractedUserId =
      typeof mockedUser?.sub === 'string' ? mockedUser.sub.split('|')[1] : null
    console.log(extractedUserId, 'extractedUserId')

    const userDoc = await firebase.firestore().collection('users').doc(extractedUserId).get()
    const userEmailDoc = await firebase
      .firestore()
      .collection('users')
      .where('email', '==', mockedUser?.email)
      .get()
    console.log(userDoc.data(), 'userDocuserDoc')
    console.log(userDoc.exists, 'userDocuserDocexist')
    if (userDoc.exists || !userEmailDoc.empty) {
      console.log('Ahsan Iqbal developer')
      if (userDoc.data()?.status || userEmailDoc.docs[0].data()?.status !== 'blocked') {
        if (userDoc.data()) {
          onSuccess({uid: extractedUserId, ...userDoc.data()})
          await firebase.firestore().collection('users').doc(extractedUserId).set(
            {
              lastLoginTime: firebase.firestore.Timestamp.now(),
            },
            {merge: true}
          )
          createOrUpdateLoginHistory(extractedUserId)
        } else {
          onSuccess({uid: userEmailDoc.docs[0].id, ...userEmailDoc.docs[0].data()})
          await firebase.firestore().collection('users').doc(userEmailDoc.docs[0].id).set(
            {
              lastLoginTime: firebase.firestore.Timestamp.now(),
            },
            {merge: true}
          )
          createOrUpdateLoginHistory(userEmailDoc.docs[0].id)
        }
      } else {
        // alert('You are blocked by the Admin. For more details please contact Admin')
        // onSuccess('failed')
        onError('You are blocked by the Admin. For more details please contact Admin')
      }
    } else {
      const firstName = mockedUser?.name ? mockedUser?.name.split(' ')[0] : ''
      const lastName = mockedUser?.name ? mockedUser?.name.split(' ')[1] : ''
      const response = await BillingApi.createCustomerAndSubscribeFreePlan({
        email: mockedUser?.email,
        name: firstName + ' ' + lastName,
      })

      const obj = {
        firstName,
        lastName,
        email: mockedUser?.email,
        profileImage: mockedUser?.picture,
        timeZone: countryTimezone,
        role: 'super-admin',
        status: 'active',
        allowFreeAccess: false,
        createdAt: firebase.firestore.Timestamp.now(),
        notificationsSettings: {
          essential: true,
          weeklyReports: false,
          monthlyReports: false,
          openTracking: false,
        },
        usage: {
          videoMessages: 0,
          users: 0,
          storage: 0,
        },
        platform: 'gmail',
        priceId: response?.data?.data?.planDetails?.id,
        subscriptionId: response?.data?.data?.subscriptionDetails?.id,
        customerId: response?.data?.data?.customerId,
        unreadNotifications: 0,
      }

      console.log(obj, 'objbyahsaniqbal')

      await firebase
        .firestore()
        .collection('users')
        .doc(extractedUserId)
        .set(obj)
        .then(() => {
          onSuccess({uid: extractedUserId, ...obj})
        })
    }
  } catch (error) {
    console.error('LinkedIn login error:', error)
    alert('LinkedIn login failed')
    onSuccess('failed')
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }
export async function resetPassword(email: string): Promise<ResetPasswordResult> {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    return {success: true, message: 'Password reset email sent successfully.'}
  } catch (error) {
    const errorMessage = (error as Error).message
    return {success: false, message: errorMessage}
  }
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

const createOrUpdateLoginHistory = async (userId: string | undefined) => {
  try {
    const loginTime = new Date()
    const loginTimeNanoseconds = performance.now() * 1e6 // Convert milliseconds to nanoseconds
    console.log(loginTimeNanoseconds, '=========')
    const loginHistoryRef = firebase.firestore().collection('loginHistory').doc(userId)

    // Check if the login history document exists
    const loginHistoryDoc = await loginHistoryRef.get()

    if (loginHistoryDoc.exists) {
      // Update the existing document
      await loginHistoryRef.update({
        loginTime,
        loginTimeNanoseconds,
      })
    } else {
      // Create a new document
      await loginHistoryRef.set({
        userId,
        loginTime,
        loginTimeNanoseconds,
      })
    }

    console.log('Login history created or updated successfully')
  } catch (error) {
    console.error('Error creating or updating login history: ', error)
  }
}

export const getSearchData = async (userId: string | undefined) => {
  const opportunitiesQuery = firebase
    .firestore()
    .collection('opportunity')
    .where('createdById', '==', userId)

  const contactsQuery = firebase
    .firestore()
    .collection('contacts')
    .where('createdById', '==', userId)

  try {
    const [opportunitiesSnapshot, contactsSnapshot] = await Promise.all([
      opportunitiesQuery.get(),
      contactsQuery.get(),
    ])

    const opportunities = []
    let videos: any = []
    const contacts: any = []

    // Process opportunities
    for (const doc of opportunitiesSnapshot.docs) {
      const opportunityData = {id: doc.id, ...doc.data()}
      opportunities.push(opportunityData)

      const videosSnapshot = await firebase
        .firestore()
        .collection('recordedVideos')
        .where('opportunity', '==', doc.id)
        .get()

      // Process videos
      videosSnapshot.forEach((vid) => {
        videos.push({id: vid.id, ...vid.data()})
      })
    }

    // Process contacts
    contactsSnapshot.forEach((doc) => {
      contacts.push({id: doc.id, ...doc.data()})
    })

    const searchData = {opportunities, videos, contacts}
    return searchData
  } catch (error) {
    console.error('Error fetching search data:', error)
    throw error
  }
}

export const checkUserStatus = (id: any, onSuccess = (data: any) => {}) => {
  try {
    firebase
      .firestore()
      .collection('users')
      .doc(id)
      .onSnapshot((snapshot) => {
        const data = {...snapshot.data(), uid: snapshot.id}
        onSuccess(data)
      })
  } catch (error) {
    console.log(error)
  }
}
