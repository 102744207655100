export const calculateSumForStats = (opportunities: any, property: any) => {
  return opportunities.reduce((accumulator: any, item: any) => {
    return (
      accumulator +
      (item.opportunityStats && item.opportunityStats[property]
        ? item.opportunityStats[property]
        : 0)
    )
  }, 0)
}
