import React from 'react'
import Lottie from 'react-lottie'
import animationData from './animation.json' // Import your .json animation file

function EmailAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // the animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div>
      {/* @ts-ignore */}
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  )
}

export default EmailAnimation
