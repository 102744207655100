import {FC} from 'react'
import {User} from '../../../user-management/users-list/core/_models'
import {Link, useNavigate} from 'react-router-dom'

type Props = {
  opportunity: any
}

const OpportunityCell: FC<Props> = ({opportunity}) => {
  const navigate = useNavigate()
  return (
    <>
      <div
        // to={`/opportunities/singleOpportunity/${opportunity.opportunityType}/${opportunity.id}`}
        className='text-gray-500 cursor-pointer'
        onClick={() => {
          navigate(
            `/opportunities/singleOpportunity/${opportunity.opportunityType}/${opportunity.id}`,
            {
              state: {
                pageTitle: opportunity?.opportunityName,
                pageDescription: `Search > ... > ${opportunity?.opportunityName}`,
                hoverDescription: `Search > View Single Opportunity > ${opportunity?.opportunityName}`,
              },
            }
          )
        }}
      >
        {opportunity?.opportunityName}
      </div>
    </>
  )
}

export {OpportunityCell}
