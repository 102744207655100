import React, { useEffect, useRef } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {formatedDate} from '../../../../_metronic/helpers/FormatDate'

export const RequestedVideos: React.FC<any> = ({data, name, type}) => {
  const navigate = useNavigate()
  const handleStatus = (item: any) => {
    if (item.isOpened === false) {
      return 'Not opened'
    } else if (item.isOpened && !item.fullyWatched) {
      return 'Opened'
    } else if (item.isOpened && item.fullyWatched) {
      return 'Viewed'
    }
  }
  const handleStatusColor = (item: any) => {
    if (!item.isOpened) {
      return 'bg-light text-warning'
    } else if (item.isOpened && !item.fullyWatched) {
      return 'bg-light text-primary'
    } else if (item.isOpened && item.fullyWatched) {
      return 'bg-light text-success'
    }
  }

  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    const handlePlayEvent = () => {
      if (videoRef.current) {
        const trackList = videoRef.current.textTracks
        for (let i = 0; i < trackList.length; i++) {
          if (trackList[i].kind === 'subtitles' && trackList[i].language === 'en') {
            trackList[i].mode = 'showing'
          }
        }
      }
    }

    const videoElement = videoRef.current
    if (videoElement) {
      videoElement.addEventListener('play', handlePlayEvent)
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlayEvent)
      }
    }
  }, [])


  return (
    <div className='container-fluid my-5 px-0'>
      <div className='row'>
        {data?.map((item: any, index: number) => {
          return (
            <div
              // to={`/videoMessages/view-requested-video/${item.id}`}
              className='col-xl-3 col-lg-4 col-sm-6 col-xs-6 d-flex align-items-stretch p-3 cursor-pointer'
              key={index}
              onClick={() => {
                if (type && type == 'opportunity') {
                  navigate(`/videoMessages/view-requested-video/${item.id}`, {
                    state: {
                      pageTitle: `From: ${item?.videoSenderName}`,
                      hoverDescription: `Opportunities > View Single Opportunity > ${name} > ${item?.videoSenderName}`,
                      pageDescription: `Opportunities > … > ${item?.videoSenderName}`,
                    },
                  })
                } else {
                  navigate(`/videoMessages/view-requested-video/${item.id}`, {
                    state: {
                      pageTitle: `From: ${item?.videoSenderName}`,
                      hoverDescription: `Dashboard > Video Requested > ${item?.videoSenderName}`,
                      pageDescription: `Dashboard > … > ${item?.videoSenderName}`,
                    },
                  })
                }
              }}
            >
              <div className='card shadow-sm video__card'>
                <video
                  ref={videoRef}
                  controls={true}
                  poster={item?.thumbnailUrl || ''}
                  className='card-img-top w-100 video-container p-5 pb-0'
                  crossOrigin='anonymous'
                >
                  <source src={`${item.videoUrl}#t=0.001`} type='video/mp4' />
                  {item?.captions == null ? (
                    ''
                  ) : (
                    <track
                      src={`${item?.captions?.caption_url}`}
                      kind='subtitles'
                      srcLang='en'
                      label='English'
                      default
                    />
                  )}
                </video>

                <div className='card-body p-5'>
                  <div className='mb-4'>
                    <h5 className='text-pink fw-bolder'>{item.videoTitle}</h5>
                  </div>
                  <div className='row my-2'>
                    <span className='col-4 text-blue fw-bold'>Date</span>
                    <span className='col-8 text-body-text'>{formatedDate(item.createdAt)}</span>
                  </div>
                  <div className='row my-2'>
                    <span className='col-4 text-blue fw-bold'>Sender</span>
                    <span className='col-8 text-body-text'>
                      {item.videoSenderName.length > 18
                        ? `${item.videoSenderName.substring(0, 18)}...`
                        : item.videoSenderName}
                    </span>
                  </div>
                  <div className='row my-2'>
                    <span className='col-4 text-blue fw-bold'>Status</span>
                    <span className='col-8  d-flex'>
                      <div className={`${handleStatusColor(item)}  py-1 px-2 rounded`}>
                        {handleStatus(item)}
                      </div>
                    </span>{' '}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RequestedVideos
