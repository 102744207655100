/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {continueWithGoogle, continueWithLinkedIn, getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {BsEye, BsEyeSlash} from 'react-icons/bs'
import MessageAlert from '../../apps/global/MessageAlert'
import axios from 'axios'
import {timeZones} from './RegisterTimezone'
import {useAuth0} from '@auth0/auth0-react'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const {loginWithPopup} = useAuth0()
  const {isAuthenticated} = useAuth0()
  console.log(isAuthenticated, 'isAuthenticated')
  const {user} = useAuth0()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoginWithLinkedin, setIsLoginWithLinkedin] = useState(false)
  const [country, setCountry] = useState<string>('')
  console.log(country, 'ahsan')
  const [countryTimezone, setCountryTimezone] = useState<string>('')
  console.log(countryTimezone, 'countryTimezone')
  const {saveAuth, setCurrentUser} = useAuth()
  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }

  // Alert Ends

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      setLoading(true)
      try {
        await login(
          values.email,
          values.password,
          (response: any) => {
            if (response == 'failed') {
              saveAuth(undefined)
              setSubmitting(false)
              setLoading(false)
            } else if (response == 'reactive') {
              setMessage('Account reactivated successfully. Please log in again')
              setAlertType('success')
              alertToggle()

              saveAuth(undefined)
              setSubmitting(false)
              setLoading(false)
              resetForm()
            } else {
              console.log(response)
              saveAuth(response)
              setCurrentUser(response)
              setSubmitting(false)
              setLoading(false)
            }
          },
          (err: any) => {
            setMessage(err || 'Unable to create account!')
            if (err?.includes('Email not verified')) {
              setAlertType('warning')
            } else {
              setAlertType('error')
            }
            alertToggle()
            saveAuth(undefined)
            setSubmitting(false)
            setLoading(false)
          }
        )
      } catch (error) {
        // console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const fetchData = async (): Promise<void> => {
    try {
      const response = await axios.get<string>('https://api.ipify.org')
      const ipAddress: string = response.data

      const countryDetail = await axios.get<{city: string}>(
        `https://ipinfo.io/${ipAddress}/json?token=5a6086648352e7`
      )
      const {city} = countryDetail.data

      setCountry(city)

      // console.log(city,countryDetail, 'country');
    } catch (error) {
      console.error('Error fetching IP data:', error)
    }
  }

  const handleLogin = async () => {
    try {
      await loginWithPopup()
    } catch (error) {
      console.error('Error during login', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    console.log(
      timeZones.find((el) => el.value === country),
      'timeZones'
    )
    const timezoneOfCountry = timeZones.find((el) => el.value === country)
    console.log(timezoneOfCountry, 'timezoneOfCountry')
    if (timezoneOfCountry) {
      setCountryTimezone(timezoneOfCountry.label)
    }
  }, [country])

  useMemo(async () => {
    if (isAuthenticated && user && isLoginWithLinkedin) {
      const mockedUser = user
      await continueWithLinkedIn(
        mockedUser,
        countryTimezone,
        (res: any) => {
          console.log(res, 'continueLoginMemo')
          saveAuth(res)
          setCurrentUser(res)
          setLoading(false)
        },
        (err: any) => {
          setMessage(err || 'Unable to create account!')
          setAlertType('error')
          alertToggle()
          saveAuth(undefined)
          setLoading(false)
        }
      )
    }
  }, [isAuthenticated, user])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Log In!</h1>
        <div className='text-gray-400 fw-semibold fs-4'>
          Don't have an account yet?{' '}
          <Link to='/auth/registration' className='link-pink fw-'>
            join here
          </Link>
        </div>
      </div>
      <div className='d-block'>
        <span
          onClick={() => {
            setLoading(true)
            continueWithGoogle(
              countryTimezone,
              (res: any) => {
                saveAuth(res)
                setCurrentUser(res)
                setLoading(false)
              },
              (err: any) => {
                setMessage(err || 'Unable to create account!')
                setAlertType('error')
                alertToggle()
                saveAuth(undefined)
                setLoading(false)
              }
            )
            // continueWithGoogle((res: any) => {
            //   if (res == 'failed') {
            //     saveAuth(undefined)
            //     setLoading(false)
            //   } else {
            //     saveAuth(res)
            //     setCurrentUser(res)
            //     setLoading(false)
            //   }
            // })
          }}
          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 me-md-2 me-0 border'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Log in with Google
        </span>
        <div
          onClick={() => {
            setLoading(true)
            setIsLoginWithLinkedin(true)
            handleLogin()
          }}
          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 me-md-2 me-0 border'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')}
            className='h-20px me-3'
          />
          Log in with LinkedIn
        </div>
      </div>

      {/* begin::Separator */}
      <div className='text-center text-muted  mb-5 d-flex justify-content-between align-items-center'>
        <hr className='w-100' />
        <div className='w-100'>Or with email</div>
        <hr className='w-100' />
      </div>
      {/* end::Separator */}
      {/* begin::Heading */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bold text-dark'>Email</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bold text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <div className='position-relative d-flex align-items-center'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid'
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
          />
          <div className='position-absolute p-1 end-0 me-4'>
            {showPassword ? (
              <BsEyeSlash
                onClick={() => setShowPassword(!showPassword)}
                size={20}
                color='#6c6c6c'
              />
            ) : (
              <BsEye onClick={() => setShowPassword(!showPassword)} size={20} color='#6c6c6c' />
            )}{' '}
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <Link
        to='/auth/forgot-password'
        className='link-pink fs-6 mb-5 fw-bold ms-auto d-flex justify-content-end'
      >
        Forgot Password ?
      </Link>
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Log in</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </form>
  )
}
