/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  videosent: any
}

const OpportunityVideoCell: FC<Props> = ({videosent}) => (
  <>
    <div className=''>{videosent?.opportunityStats.videosSent}</div>
  </>
)

export {OpportunityVideoCell}
