import Repository from './Repository'

export default {
  createUser(payload: any) {
    return Repository.post('/user/create-user', payload)
  },
  updateUser(payload: any) {
    return Repository.put('/user/update', payload)
  },
  deleteUser(payload: {id: any}) {
    return Repository.delete(`/user/delete-user/${payload.id}`)
  },
  disableUser(payload: any) {
    return Repository.put(`/user/disable-user/${payload.id}`)
  },
  enableUser(payload: any) {
    return Repository.put(`/user/enable-user/${payload.id}`)
  },
  sendEmail(payload: any) {
    return Repository.post(`/user/send-email`, payload)
  },
  sendQuery(payload: any) {
    return Repository.post(`/user/contactUsEmail`, payload)
  },
  unsubscribeEmail(payload: any) {
    return Repository.get(`/user/unsubscribe-email/${payload}`)
  },
  sendVerificationEmail(payload: any) {
    return Repository.post(`/user/send-verification-email`, payload)
  },
  verifyDomain(payload: any) {
    console.log(payload,'payload')
    return Repository.post(`/user/add-smtp`, payload)
  },

  updateDomain(payload: any) {
    console.log(payload,'payload')
    return Repository.post(`/user/add-smtp`, payload)
  },
  updateUserDomain(payload: any) {
    console.log(payload,'payload')
    return Repository.post(`/user/add-user-smtp`, payload)
  },
  resendUserVerificationEmail(payload: any) {
    console.log(payload,'payload')
    return Repository.post(`/user/resend-verification-mail`, payload)
  },
  verifyUserStatus(payload: any) {
    console.log(payload,'payload')
    return Repository.get(`/user/email-status/${payload}`)
  },
  addAdditionalUsers(payload:any) {
    return Repository.post(`/payment/subscribe`, payload)
  }
}