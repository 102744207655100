export const handleVideoStatus = (item: any) => {
  if (item?.recipientReaction === 'not-interested') {
    return 'Not interested'
  } else if (item?.recipientReaction !== 'not-interested' && item?.recipientReaction !== '') {
    return 'Interested'
  } else if (!item.isOpened) {
    return 'Un-opened'
  } else if (item.isOpened && !item.partiallyWatched) {
    return 'Opened'
  } else if (item.partiallyWatched && !item.fullyWatched) {
    return 'Partially watched'
  } else if (item.fullyWatched) {
    return 'Viewed'
  }
}

export const handleVideoStatusColor = (item: any) => {
  if (item.recipientReaction === 'not-interested') {
    return 'bg-danger text-white'
  } else if (item?.recipientReaction !== 'not-interested' && item?.recipientReaction !== '') {
    return 'bg-success text-light'
  } else if (!item.isOpened) {
    return 'bg-light text-warning'
  } else if (item.isOpened && !item.partiallyWatched) {
    return 'bg-light text-primary'
  } else if (item.partiallyWatched && !item.fullyWatched) {
    return 'bg-light text-green'
  } else if (item.fullyWatched) {
    return 'bg-light text-success'
  }
}
