import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {unsubscribeEmailNotification} from '../../apps/user-management/users-list/core/_requests'
import Loader from '../../accounts/components/Loading/Loader'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const UnsubscribeEmail = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const params: any = useParams()
  console.log(params.email)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const response = await unsubscribeEmailNotification(params?.email)
      if (response.data.success) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div className='card w-100 min-h-50 mt-12 p-12 d-flex justify-content-center align-items-center'>
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <div className='d-flex flex-column  justify-content-center align-items-center'>
          <img
            width={60}
            height={60}
            src={toAbsoluteUrl('/media/svg/landscape-modals/tick.svg')}
            alt='icon'
          />
          <h2 className='text-center text-gray-500 mt-12'>
            You have successfully unsubscribed greetrs emails.
          </h2>
        </div>
      ) : (
        <div className='d-flex flex-column  justify-content-center align-items-center'>
          <img
            width={60}
            height={60}
            src={toAbsoluteUrl('/media/svg/landscape-modals/cross.svg')}
            alt='icon'
          />
          <h2 className='text-center text-gray-500 mt-12'>
            Something went wrong please contact admin
          </h2>
        </div>
      )}
    </div>
  )
}

export default UnsubscribeEmail
