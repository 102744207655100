import {Column} from 'react-table'
// import {User} from '../../core/_models'
import {OpportunityCustomHeader} from './OpportunityCustomHeader'
import {OpportunityCell} from './OpportunityCell'
import {OpportunityReceivedCell} from './OpportunityReceivedCell'
import {OpportunityNotOpenedCell} from './OpportunityNotOpenedCell'
import {OpportunityViewedCell} from './OpportunityViewedCell'
import {OpportunityRequestedCell} from './OpportunityRequestedCell'
import {OpportunityActionsCell} from './OpportunityActionsCell'

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Opportunity' className='min-w-125px' />
    ),
    id: 'opportunity',
    Cell: ({...props}) => <OpportunityCell opportunity={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader
        tableProps={props}
        title='Videos requested'
        className='min-w-125px'
      />
    ),
    id: 'videorequested',
    Cell: ({...props}) => <OpportunityRequestedCell requested={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Videos received' className='min-w-125px' />
    ),
    id: 'videoreceived',
    Cell: ({...props}) => <OpportunityReceivedCell received={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Videos viewed' className='min-w-125px' />
    ),
    id: 'viewed',
    Cell: ({...props}) => <OpportunityViewedCell viewed={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader
        tableProps={props}
        title='Videos not opened'
        className='min-w-125px'
      />
    ),
    id: 'videosnotopened',
    Cell: ({...props}) => <OpportunityNotOpenedCell notOpened={props.data[props.row.index]} />,
  },

  // {
  //   Header: (props) => (
  //     <OpportunityCustomHeader tableProps={props} className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => (
  //     <OpportunityActionsCell
  //       id={props.data[props.row.index].id}
  //       type={props.data[props.row.index].opportunityType}
  //     />
  //   ),
  // },
]

export {usersColumns}
