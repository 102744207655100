import React from 'react'
import Lottie from 'react-lottie'
import animationData from './videoAnimation.json' // Import your .json animation file
import {FullPageOverlay} from '../../accounts/components/Loading/Loader'

function VideoAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // the animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <FullPageOverlay>
      <div>
        {/* @ts-ignore */}
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
    </FullPageOverlay>
  )
}

export default VideoAnimation
