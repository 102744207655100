/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {
  getLimitedDropdownNotifications,
  readNotification,
} from '../../../../app/modules/apps/notifications/components/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {formatedDate} from '../../../helpers/FormatDate'
import Loader from '../../../../app/modules/accounts/components/Loading/Loader'
import { formatCreatedAt } from '../../../helpers/FormatTime'
interface HeaderNotificationsMenuProps {
  closeDropdown: () => void
}

const HeaderNotificationsMenu: React.FC<HeaderNotificationsMenuProps> = ({closeDropdown}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [notificationList, setNotificationList] = useState<any>([])
  console.log(notificationList)
  useEffect(() => {
    const getAll = async () => {
      setIsLoading(true)
      await getLimitedDropdownNotifications(
        currentUser?.uid,
        (res: any) => {
          setNotificationList(res)
          setIsLoading(false)
        },
        (error: any) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    }
    getAll()
  }, [])

  const handleReadMessage = (item: any) => {
    if (!item.isRead) {
      readNotification(item.id)
    }

    if (item?.redirectUrl) {
      let stateObj = {}
      if (item?.pageTitle) {
        stateObj = {...stateObj, pageTitle: item?.pageTitle}
      }
      if (item?.pageDescription) {
        stateObj = {...stateObj, pageDescription: item?.pageDescription}
      }

      if (item?.hoverDescription) {
        stateObj = {...stateObj, hoverDescription: item?.hoverDescription}
      }

      navigate(item?.redirectUrl, {
        state: stateObj,
      })
    }

    closeDropdown()
  }


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-275px w-lg-375px'
      data-kt-menu='true'
      id='notification-main-menu'
    >
      <div className='d-flex flex-row justify-content-between rounded-top'>
        <div className='col mt-10 mb-6 px-9'>
          <h3 className='fw-semibold '>Notifications</h3>
        </div>
        <div className='col text-end mt-10 mb-6 px-9'>
          <div
            // to='/notifications/settings'
            className='d-flex justify-content-end cursor-pointer menu-link cursor-pointer'
            onClick={() => {
              console.log('Clicke...')
              navigate('/notifications/settings')
              closeDropdown()
            }}
          >
            <i className='fa-solid fa-gear fs-2 mx-1'></i>
            {/* <i className='fa-solid fa-chevron-down fs-4 mx-1'></i> */}
          </div>
        </div>

        {/* <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9'>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_1'
          >
            Alerts
          </a>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_2'
          >
            Updates
          </a>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_3'
          >
            Logs
          </a>
        </li>
      </ul> */}
      </div>

      <div className='separator my-2'></div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          {isLoading ? (
            <div className='d-flex justify-content-center'>
              <Loader />
            </div>
          ) : (
            <div className='scroll-y mh-225px mh-lg-375px my-5 px-8'>
              {notificationList.length === 0 && (
                <h6 className='fs-6 text-gray-800 mb-0 fw-bold text-center py-4 mb-2'>
                  No live notification
                </h6>
              )}
              {notificationList.map((alert: any, index: any) => (
                <div
                  key={`alert${index}`}
                  className={`py-4 ${
                    alert.isRead ? 'border' : 'bg-light'
                  } mb-1 px-3 rounded cursor-pointer`}
                  onClick={() => handleReadMessage(alert)}
                >
                  <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='fs-6 text-gray-800 text-hover-primary fw-bold mb-0'>
                      {alert.userName}
                    </h6>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <p className='text-gray-700 fs-7 mb-0'>
                      {alert.message} at {formatCreatedAt(alert?.createdAt)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {notificationList.length > 0 && (
            <div className='py-3 text-center border-top'>
              <div
                // to='/notifications/all'
                className='btn btn-color-gray-600 btn-active-color-primary cursor-pointer'
                // onClick={closeDropdown}
                onClick={() => {
                  navigate('/notifications/all')
                  closeDropdown()
                }}
              >
                View All{' '}
                <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
              </div>
            </div>
          )}
        </div>

        {/* <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
      <div className='scroll-y mh-325px my-5 px-8'>
        {defaultAlerts.map((alert, index) => (
          <div key={`alert${index}`} className='d-flex flex-stack py-4'>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-35px me-4'>
                <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                  {' '}
                  <KTSVG
                    path={`/media/${alert.icon}`}
                    className={`svg-icon-2 svg-icon-${alert.state}`}
                  />
                </span>
              </div>

              <div className='mb-0 me-2'>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                  {alert.title}
                </a>
                <div className='text-gray-400 fs-7'>{alert.description}</div>
              </div>
            </div>

            <span className='badge badge-light fs-8'>{alert.time}</span>
          </div>
        ))}
      </div>
      </div> */}

        {/* <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
        <div className='d-flex flex-column px-9'>
          <div className='pt-10 pb-0'>
            <h3 className='text-dark text-center fw-bold'>Get Pro Access</h3>

            <div className='text-center text-gray-600 fw-semibold pt-1'>
              Outlines keep you honest. They stoping you from amazing poorly about drive
            </div>

            <div className='text-center mt-5 mb-9'>
              <a
                href='#'
                className='btn btn-sm btn-primary px-6'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upgrade_plan'
              >
                Upgrade
              </a>
            </div>
          </div>

          <div className='text-center px-4'>
            <img
              className='mw-100 mh-200px'
              alt='metronic'
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/1.png')}
            />
          </div>
        </div>
      </div> */}

        {/* <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a href='#' className='text-gray-800 text-hover-primary fw-semibold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='py-3 text-center border-top'>
          <Link
            to='/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
          </Link>
        </div>
      </div> */}
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
